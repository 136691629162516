import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import SpecialFolderImg from "../../assets/images/unified-folder/special-folder.webp"
import SpecialFolderMImg from "../../assets/images/unified-folder/special-folder-m.webp"
import MultipleAccountsMImg from "../../assets/images/unified-folder/multiple-accounts-m.webp"
import AllInOneMImg from "../../assets/images/unified-folder/all-in-one-m.webp"

const UnifiedInbox = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Unified Inbox | BlueMail App"
        postDescription="Using the Unified Inbox, syncing multiple accounts from various providers is effortless with BlueMail. It automatically adds and configures IMAP, POP3, and Exchange accounts, with lightning-fast push services."
        postImage="/img/OG/og_image-unified-feature.png"
        postURL="features-functions/unified-inbox"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid multiple-accounts w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-xs-12">
                <h1>
                  Multiple Accounts from <span>multiple</span> providers
                </h1>
                <p>
                  With BlueMail you can combine multiple accounts from multiple
                  providers into a single view. You can access each separately
                  or use the Unified Inbox to manage all from one place.
                  Consolidation has never been easier. Pretty convenient.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={MultipleAccountsMImg} alt="Multiple accounts" />
            </div>
          </div>
        </div>
        <div className="container-fluid all-in-one w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>All-in-One</span>
                </h2>
                <p>
                  With the Unified Inbox, syncing many accounts from many
                  providers is not only simple, setting up is a snap. BlueMail
                  automatically adds and configures your IMAP, POP3, & Exchange
                  accounts while also enabling you to manage all your email
                  accounts from one place.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={AllInOneMImg} alt="All in one" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  Unified Special <span>Folders</span>
                </h2>
                <p>
                  Unified folders are not confined to Inbox. BlueMail
                  automatically displays the relevant special Unified Folders
                  such as Inbox, Drafts, Sent, Trash, Spam, Outbox, and Archive.
                  And you have the ability to view and perform actions on
                  multiple accounts while in one view. It’s that easy.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={SpecialFolderImg}
                alt="Special folder"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={SpecialFolderMImg}
                alt="special folder mobile"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default UnifiedInbox

export const query = graphql`
  query UnifiedInboxPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
